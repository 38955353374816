import { checkAyahForSurah } from "../utils/Utils";

async function getAyahAudio(reciter, surah, ayah) {
    ayah = checkAyahForSurah(ayah, surah);
    const data = await fetch(`https://api.quran.com/api/v4/recitations/${reciter}/by_ayah/${surah}:${ayah}`);
    return data.json()
}

async function getAyahText(surah, ayah) {
    ayah = checkAyahForSurah(ayah, surah);
    const data = await fetch(`https://api.quran.com/api/v4/quran/verses/uthmani?verse_key=${surah}:${ayah}`)
    const text = await data.json();
    return text["verses"][0]["text_uthmani"];
}

export { getAyahAudio, getAyahText };