import { surahToAyahCount } from "../constants/MappingConstants";

function checkAyahForSurah(ayahValue, surahValue) {
    const limit = surahToAyahCount[surahValue];
    return ayahValue > limit ? 1 : ayahValue;
}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export { checkAyahForSurah, getKeyByValue };