const surahToAyahCount = {
    1: 7,
    2: 286,
    3: 200,
    4: 176,
    5: 120,
    6: 165,
    7: 206,
    8: 75,
    9: 129,
    10: 109,
    11: 123,
    12: 111,
    13: 43,
    14: 52,
    15: 99,
    16: 128,
    17: 111,
    18: 110,
    19: 98,
    20: 135,
    21: 112,
    22: 78,
    23: 118,
    24: 64,
    25: 77,
    26: 227,
    27: 93,
    28: 88,
    29: 69,
    30: 60,
    31: 34,
    32: 30,
    33: 73,
    34: 54,
    35: 45,
    36: 83,
    37: 182,
    38: 88,
    39: 75,
    40: 85,
    41: 54,
    42: 53,
    43: 89,
    44: 59,
    45: 37,
    46: 35,
    47: 38,
    48: 29,
    49: 18,
    50: 45,
    51: 60,
    52: 49,
    53: 62,
    54: 55,
    55: 78,
    56: 96,
    57: 29,
    58: 22,
    59: 24,
    60: 13,
    61: 14,
    62: 11,
    63: 11,
    64: 18,
    65: 12,
    66: 12,
    67: 30,
    68: 52,
    69: 52,
    70: 44,
    71: 28,
    72: 28,
    73: 20,
    74: 56,
    75: 40,
    76: 31,
    77: 50,
    78: 40,
    79: 46,
    80: 42,
    81: 29,
    82: 19,
    83: 36,
    84: 25,
    85: 22,
    86: 17,
    87: 19,
    88: 26,
    89: 30,
    90: 20,
    91: 15,
    92: 21,
    93: 11,
    94: 8,
    95: 8,
    96: 19,
    97: 5,
    98: 8,
    99: 8,
    100: 11,
    101: 11,
    102: 8,
    103: 3,
    104: 9,
    105: 5,
    106: 4,
    107: 7,
    108: 3,
    109: 6,
    110: 3,
    111: 5,
    112: 4,
    113: 5,
    114: 6,
};
const surahMap = {
    "Al-Fatihah": 1,
    "Al-Baqarah": 2,
    "Aali Imran": 3,
    "An-Nisa'": 4,
    "Al-Ma'idah": 5,
    "Al-An'am": 6,
    "Al-A'raf": 7,
    "Al-Anfal": 8,
    "At-Tawbah": 9,
    "Yunus": 10,
    "Hud": 11,
    "Yusuf": 12,
    "Ar-Ra'd": 13,
    "Ibrahim": 14,
    "Al-Hijr": 15,
    "An-Nahl": 16,
    "Al-Isra'": 17,
    "Al-Kahf": 18,
    "Maryam": 19,
    "Ta-Ha": 20,
    "Al-Anbiya'": 21,
    "Al-Hajj": 22,
    "Al-Mu'minun": 23,
    "An-Nur": 24,
    "Al-Furqan": 25,
    "Ash-Shu'ara": 26,
    "An-Naml": 27,
    "Al-Qasas": 28,
    "Al-Ankabut": 29,
    "Ar-Rum": 30,
    "Luqman": 31,
    "As-Sajda": 32,
    "Al-Ahzab": 33,
    "Saba": 34,
    "Fatir": 35,
    "Ya-Sin": 36,
    "As-Saffat": 37,
    "Sad": 38,
    "Az-Zumar": 39,
    "Ghafir": 40,
    "Fussilat": 41,
    "Ash-Shura": 42,
    "Az-Zukhruf": 43,
    "Ad-Dukhan": 44,
    "Al-Jathiyah": 45,
    "Al-Ahqaf": 46,
    "Muhammad": 47,
    "Al-Fath": 48,
    "Al-Hujurat": 49,
    "Qaf": 50,
    "Adh-Dhariyat": 51,
    "At-Tur": 52,
    "An-Najm": 53,
    "Al-Qamar": 54,
    "Ar-Rahman": 55,
    "Al-Waqi'a": 56,
    "Al-Hadid": 57,
    "Al-Mujadila": 58,
    "Al-Hashr": 59,
    "Al-Mumtahina": 60,
    "As-Saff": 61,
    "Al-Jumu'a": 62,
    "Al-Munafiqun": 63,
    "At-Taghabun": 64,
    "At-Talaq": 65,
    "At-Tahrim": 66,
    "Al-Mulk": 67,
    "Al-Qalam": 68,
    "Al-Haaqqa": 69,
    "Al-Ma'arij": 70,
    "Nuh": 71,
    "Al-Jinn": 72,
    "Al-Muzzammil": 73,
    "Al-Muddaththir": 74,
    "Al-Qiyamah": 75,
    "Al-Insan": 76,
    "Al-Mursalat": 77,
    "An-Naba'": 78,
    "An-Nazi'at": 79,
    "'Abasa": 80,
    "At-Takwir": 81,
    "Al-Infitar": 82,
    "Al-Mutaffifin": 83,
    "Al-Inshiqaq": 84,
    "Al-Burooj": 85,
    "At-Tariq": 86,
    "Al-A'la": 87,
    "Al-Ghashiyah": 88,
    "Al-Fajr": 89,
    "Al-Balad": 90,
    "Ash-Shams": 91,
    "Al-Layl": 92,
    "Ad-Duha": 93,
    "Ash-Sharh": 94,
    "At-Tin": 95,
    "Al-'Alaq": 96,
    "Al-Qadr": 97,
    "Al-Bayyina": 98,
    "Az-Zalzalah": 99,
    "Al-'Adiyat": 100,
    "Al-Qari'ah": 101,
    "At-Takathur": 102,
    "Al-'Asr": 103,
    "Al-Humazah": 104,
    "Al-Fil": 105,
    "Quraish": 106,
    "Al-Ma'un": 107,
    "Al-Kawthar": 108,
    "Al-Kafirun": 109,
    "An-Nasr": 110,
    "Al-Masad": 111,
    "Al-Ikhlas": 112,
    "Al-Falaq": 113,
    "An-Nas": 114
};

export { surahMap, surahToAyahCount }