import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectInput(props) {
  const [value, setValue] = React.useState('');
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    setItems(props.items);
    items.forEach((element) => {
        // console.log(element);
        // <MenuItem value={element.value}>{element.label}</MenuItem>
    });
  }, []);

  const handleChange = (event) => {
    props.items.forEach((element) => {
        // console.log(element);
        // <MenuItem value={element.value}>{element.label}</MenuItem>
    });
    setValue(event.target.value);
    props.setter(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={props.label}
          onChange={handleChange}
        >
            {props.items 
                 ? props.items.map((element) => (
                     <MenuItem key={element.label} value={element.value}>{element.label}</MenuItem>
                 ))
                 : null
             }
        </Select>
      </FormControl>
    </Box>
  );
}
