import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { getAyahText } from '../api/QuranApis';
import { getKeyByValue } from '../utils/Utils';
import { surahMap } from '../constants/MappingConstants';


export default function MediaControlCard(props) {
    const parseAudioUrl = (url) => {
        const parts = url.split('/');
        if (parts.length > 0) {
            const fileName = parts[parts.length - 1].split('.')[0];
            const chapter = parseInt(fileName.substring(0, 3), 10);
            const ayah = parseInt(fileName.substring(3, 6), 10);
            return { chapter, ayah };
        }
        return null;
    }

    const formatNumber = (number) => {
     return String(number).padStart(3, '0');
    };  
  const [currentAudioIndex, setCurrentAudioIndex] = React.useState(0);
  const audioRef = React.useRef(null);
  const baseUrl = props.source;
  const parsed = parseAudioUrl(props.audioUrl);
  const endAyahRange = props.endAyahRange;
  const recitor="Alafasy";
  const source = `${baseUrl}${recitor}/mp3/${formatNumber(parsed.chapter)}${formatNumber(parsed.ayah+currentAudioIndex)}.mp3`;
  const [currentSource, setCurrentSource] = React.useState(source);
  
  React.useEffect(() => {
    getAyahText(parsed.chapter, parsed.ayah+currentAudioIndex)
        .then(text => {
        props.textSetter(text);
    });
    setCurrentSource(source);
  }, [currentAudioIndex]);

  const handleAudioEnd = () => {
    setCurrentAudioIndex(prevIndex => (prevIndex + 1) % (endAyahRange+1));
    if (currentAudioIndex === 0) audioRef.current.play()
  };

  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            The Holy Quran
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Surah {getKeyByValue(surahMap, parsed.chapter)}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, pr: 1 }}>
          <audio ref={audioRef} src={currentSource} onEnded={handleAudioEnd} controls autoPlay />  
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        src="https://images.unsplash.com/photo-1609599006353-e629aaabfeae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cXVyYW58ZW58MHx8MHx8fDA%3D&w=1000&q=80"
        alt="Quran track cover"
      />
    </Card>
  );
}