import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Swirl} from 'ambient-cbg'

import SelectInput from "./components/SelectInput";
import AudioPlayer from "./audio-player/AudioPlayer";
import { surahMap, surahToAyahCount } from "./constants/MappingConstants";
import { getAyahAudio, getAyahText } from "./api/QuranApis";

const baseUrl = "https://verses.quran.com/";
function App() {
  const [ayahValue, setAyahValue] = useState(1);
  const [surahValue, setSurahValue] = useState(1);
  const [ayahAudio, setAyahAudio] = useState("https://verses.quran.com/AbdulBaset/Mujawwad/mp3/001001.mp3");
  const [endAyahRange, setEndAyahRange] = useState(0);
  const [audioUrl, setAudioUrl] = useState("AbdulBaset/Mujawwad/mp3/001001.mp3");
  const [ayahText, setAyahText] = useState("");

  useEffect(() => {
    handleSurahInput(surahValue);
  }, []);

  useEffect(() => {
    setAyahValue(1);
    setEndAyahRange(0);
  }, [surahValue]);

  useEffect(() => {
    setAyahValue(ayahValue);
    setEndAyahRange(0);
  }, [ayahValue]);

  // Listen for changes in endAyah
  useEffect(() => {
    setEndAyahRange(endAyahRange);
  }, [endAyahRange]);

  useEffect(() => {
    getAyahAudio("7", surahValue, ayahValue)
      .then(ayah => {
        const newUrl = `${baseUrl}${ayah.audio_files[0].url}`;
        setAudioUrl(newUrl);
        setAyahAudio(newUrl);
      
      });
    getAyahText(surahValue, ayahValue)
      .then(text => {
        setAyahText(text);
      });
  }, [ayahAudio, ayahValue, surahValue]);

  const surahNames = Object.keys(surahMap);
  const theme = useTheme();
  const ayahCount = surahToAyahCount[surahValue];
  let ayahOptions = Array.from({ length: ayahCount }, (_, i) => ({ value: i + 1, label: (i + 1).toString() }));
  const range = ayahCount - ayahValue + 1;
  let endRangeOptions = Array.from({ length: range }, (_, i) => ({ value: i, label: (i + ayahValue).toString() }));
  
  const handleSurahInput = (value) => {
    setSurahValue(value);
  }

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: 'dark',
        },
      })}
    >
      <CssBaseline />
      <Swirl />

      <Container
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center" 
        classname ="text-container">
          <Grid
            item
            spacing={2}
            justifyContent="center"
            alignItems="center"
            container
            direction="column"
          >
            <Grid item>
              <Typography 
              textAlign="center"
              gutterBottom
              variant="h2"
              style={{
                fontFamily: 'title font, sans-serif', // Use the font
              }}>
                Quran Looper
              </Typography>
            </Grid>
            <Grid item>
              <Typography 
              textAlign="center" 
              gutterBottom 
              variant="h3"
              style={{
                lineHeight: '2.0',
              }}>
                {ayahText}
              </Typography>
            </Grid>
        <Grid item> 
          <AudioPlayer
            key={ayahAudio} 
            textSetter={setAyahText}
            source={baseUrl} 
            surahValue={surahValue}
            ayahValue={ayahValue}
            endAyahRange={endAyahRange}
            audioUrl={audioUrl}
          />
        </Grid>
        </Grid>
        
          <Grid item>
            <SelectInput 
                label="surah" 
                items={surahNames.map((name, index) => ({ value: index + 1, label: name }))}
                setter={handleSurahInput}
                value={surahValue}
            />
          </Grid>
          <Grid item>
              <SelectInput 
              label="Start Ayah" 
              items={ayahOptions} 
              setter={setAyahValue} />
            </Grid>
            <Grid item>
              <SelectInput 
              label="End Ayah" 
              items={endRangeOptions} 
              setter={setEndAyahRange} />
            </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default App;